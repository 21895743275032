import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const IngredientPage = () => {
  return (
    <Layout>
      <h2>材料について</h2>
      <p>
        おいしいのはもちろん、 からだにも地球にもやさしい選りすぐりの材料を厳選
      </p>
      <p>
        平飼い卵、グラスフェッドバター、国産小麦粉をはじめ、
        ナッツやチョコレート、ドライフルーツなども、なるべくオーガニックのものを選んでいます
      </p>
      <p>
        季節のお菓子に使うフルーツは、
        農家さんから、できるだけケミカルなものを使わずに大切に育てられた皮ごとおいしいフルーツを届けてもらっています
      </p>
      <p>
        完全なアレルギーフリーではありませんが、
        卵やナッツ、乳製品を使わないお菓子のご用意もあります
      </p>
      <p>
        添加物はほぼ不使用 アルミニウムフリーのベーキングパウダーと、
        植物由来の乳化剤と香料を含んだチョコレートのみ使用しています
      </p>
      <p>詳細はお気軽にお問い合わせください◎</p>
    </Layout>
  )
}

export const Head = () => <Seo title="ingredient" />

export default IngredientPage
